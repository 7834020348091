import React from 'react'
import Company from '../../assets/Company.webp'

const AboutMainSection = () => {
  return (
    <div>
    {/* <img src={Company} alt='' className='w-full h-[70vh]'/> */}
      
    </div>
  )
}

export default AboutMainSection
